import { createRouter, createWebHashHistory } from 'vue-router';
const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: '/',
      redirect: '/login', 
    },
    {
      path: '/login',
      component: () => import('../pages/Login.vue'),
    },
    {
      path: '/home',
      component: () => import('../pages/index/Index.vue'),
    },
    {
      path: '/panic_buying',
      component: () => import('../pages/panic_buying/Panic_buying.vue'),
    },
    {
      path: '/me',
      component: () => import('../pages/mine/Mine.vue'),
    },
    {
      path: '/order/:status',
      component: () => import('../pages/mine/order/Order.vue'),
    },
    {
      path: '/setting',
      component: () => import('../pages/setting/Setting.vue'),
    },
    {
      path:'/editName',
      component: () => import('../pages/setting/Edit_name.vue'),
    },
    {
      path:'/editPwd',
      component: () => import('../pages/setting/Edit_pwd.vue'),
    },
    {
      path:"/userAgreement",
      component: () => import('../pages/setting/User_agreement.vue'),
    },
    {
      path:"/address",
      component: () => import('../pages/setting/Address.vue'),
    },
    {
      path:"/addAddress",
      component: () => import('../pages/setting/Add_address.vue'),
    },
    {
      path:"/editAddress",
      component: () => import('../pages/setting/Edit_address.vue'),
    },
    {
      path:"/warehouse/:type/:state",
      component:()=>import('../pages/mine/warehouse/Warehouse.vue'),
    },
    {
      path:"/bonus",
      component:()=>import('../pages/mine/property/bonus.vue'),
    },
    {
      path:"/extension",
      component:()=>import('../pages/mine/property/extension.vue'),
    },
    {
      path:"/coupons",
      component:()=>import('../pages/mine/property/coupons.vue'),
    },
    {
      path:"/fans",
      component:()=>import('../pages/mine/fans/Fans.vue'),
    },
    {
      path:"/invite",
      component:()=>import('../pages/mine/invite/Invite.vue'),
    },
    {
      path:"/payType",
      component:()=>import('../pages/mine/pay_type/Pay_type.vue'),
    },
    {
      path:"/bank/:id",
      component:()=>import('../pages/mine/pay_type/Bank.vue'),
    },
    {
      path:"/aliPay/:id",
      component:()=>import('../pages/mine/pay_type/Zfb.vue'),
    },
    {
      path:"/buying",
      component:()=>import('../pages/panic_buying/buying.vue'),
    },
    {
      path:"/goods/:goodsId",
      component:()=>import('../pages/index/Goods.vue'),
    },
    {
      path:"/submitOrder/:param",
      component:()=>import('../pages/index/Submit_order.vue'),
    },
    {
      path:"/buyOrder/:id",
      component:()=>import('../pages/index/buy_order.vue'),
    },
    {
      path:"/sign",
      component:()=>import('../components/Sign.vue')
    },
    {
      path:"/map/:orderId",
      component:()=>import('../pages/mine/map/Map.vue')
    },
    {
      path:"/shopCar",
      component:()=>import('../pages/index/shopping_car.vue')
    },
    {
      path:"/orderInfo/:orderId",
      component:()=>import('../pages/mine/order/Order_info.vue')
    }
    //
    // {
    //   path: '/order',
    //   component: () => import('../pages/MyOrder'),
    // },
  ],
});
export default router;