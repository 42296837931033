<template>
  <router-view></router-view>
  <Tabbar v-if="isShow"></Tabbar>
</template>

<script setup>
import Tabbar from "./components/Tabbar.vue";
import { ref,onMounted,watch } from "vue";
import { useRoute } from "vue-router";
const route = useRoute();
let isShow = ref(false);
watch(()=>route.fullPath,(newVal,oldVal)=>{
  if(newVal=="/login"||newVal=="/"){
    isShow.value = false;
  }else{
    isShow.value = true;
  }
})
 
</script>

<style>
html,body{
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
}
p,ul{
  margin: 0;
  padding: 0;
}
ul li{
  list-style: none;
}
div{
  box-sizing: border-box;
}
#app {
  width: 100%;
  height: 100%;
}
.van-icon-arrow-left{
  color: #f23939!important;
}
</style>
