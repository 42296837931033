import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index';
import 'amfe-flexible';
const app = createApp(App);
//import Vant from 'vant' //全局引入
import Vant from 'vant';
// import { 
//     Button,Tabbar, TabbarItem,Swipe, 
//     SwipeItem,Search,Tab, Tabs, Grid, GridItem,
//     Cell, CellGroup,NavBar,Empty,List,PullRefresh,Field,Form,AddressList,
//     AddressEdit,Popup,ActionSheet,Uploader,NoticeBar,Icon,Checkbox,Card,SubmitBar,
//     Stepper,Signature,Toast,Dialog
// } from 'vant';


// 2. 引入组件样式
import 'vant/lib/index.css';
// app.use(Button).use(Tabbar).use(TabbarItem)
// .use(Swipe).use(SwipeItem).use(Search)
// .use(Tab).use(Tabs).use(Grid).use(GridItem)
// .use(Cell).use(CellGroup).use(NavBar).use(Empty)
// .use(List).use(PullRefresh).use(Field).use(Form).use(AddressList)
// .use(AddressEdit).use(Popup).use(ActionSheet).use(Uploader).use(NoticeBar)
// .use(Icon).use(Checkbox).use(Card).use(SubmitBar).use(Stepper).use(Signature)
// .use(Toast).use(Dialog);
app.use(Vant);
app.use(router);
app.mount('#app');
